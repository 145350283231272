import React from "react";
import {DefaultLayout} from "../../organisms/DefaultLayout";
import * as styles from "../../styles/careers/intern.module.scss"
import {Link} from "gatsby";

export default () =>
  <DefaultLayout>
    <div>
      <div className={styles.cover} style={{backgroundImage: "url(https://suprieve-images.stg-matzlika.co.uk/img/career_mid/career_mid_head.png)"}}>
      </div>
    </div>

    <div className={styles.midInfo}>
      <div className={styles.midInfoContainerOne}>
        <h3>システム事業部</h3>
        <h2>システム開発・保守</h2>
      </div>
      <div className={styles.midInfoContainerTwo}>
        <h4>&nbsp;</h4>
        <p>各事業部の要望に沿ったシステム開発、保守。一般販売向けシステム開発、保守。</p>

      </div>
    </div>

    <div className={styles.overview}>
      <div className={styles.overviewContainer}>
        <div className={styles.overviewLeft}>
          <h4>採用企業/事業部</h4>
        </div>
        <div className={styles.overviewRight}>
          <p>○○○○</p>
        </div>
      </div>
      <div className={styles.overviewContainer}>
        <div className={styles.overviewLeft}>
          <h4>事業内容</h4>
        </div>
        <div className={styles.overviewRight}>
          <p>ダミーテキスト。<br/>
            ダミーテキスト。
          </p>
        </div>
      </div>
      <div className={styles.overviewContainer}>
        <div className={styles.overviewLeft}>
          <h4>雇用形態</h4>
        </div>
        <div className={styles.overviewRight}>
          <p>○○○○</p>
        </div>
      </div>
      <div className={styles.overviewContainer}>
        <div className={styles.overviewLeft}>
          <h4>勤務地</h4>
        </div>
        <div className={styles.overviewRight}>
          <p>○○○○</p>
        </div>
      </div>
      <div className={styles.overviewContainer}>
        <div className={styles.overviewLeft}>
          <h4>給与</h4>
        </div>
        <div className={styles.overviewRight}>
          <p>○○○○</p>
        </div>
      </div>
      <div className={styles.overviewContainer}>
        <div className={styles.overviewLeft}>
          <h4>昇給</h4>
        </div>
        <div className={styles.overviewRight}>
          <p>○○○○</p>
        </div>
      </div>
      <div className={styles.overviewContainer}>
        <div className={styles.overviewLeft}>
          <h4>賞与</h4>
        </div>
        <div className={styles.overviewRight}>
          <p>○○○○</p>
        </div>
      </div>
      <div className={styles.overviewContainer}>
        <div className={styles.overviewLeft}>
          <h4>評価制度</h4>
        </div>
        <div className={styles.overviewRight}>
          <p>○○○○</p>
        </div>
      </div>
      <div className={styles.overviewContainer}>
        <div className={styles.overviewLeft}>
          <h4>勤務時間</h4>
        </div>
        <div className={styles.overviewRight}>

          <p>○○○○</p>

        </div>
      </div>
      <div className={styles.overviewContainer}>
        <div className={styles.overviewLeft}>
          <h4>休日・休暇</h4>
        </div>
        <div className={styles.overviewRight}>
          <p>〇〇〇〇</p>
        </div>
      </div>
      <div className={styles.overviewContainer}>
        <div className={styles.overviewLeft}>
          <h4>各種手当（交通費含む）</h4>
        </div>
        <div className={styles.overviewRight}>
          <p>〇〇〇〇</p>
        </div>
      </div>
      <div className={styles.overviewContainer}>
        <div className={styles.overviewLeft}>
          <h4>待遇・福利厚生</h4>
        </div>
        <div className={styles.overviewRight}>
          <p>〇〇〇〇</p>
        </div>
      </div>
      <div className={styles.overviewContainer}>
        <div className={styles.overviewLeft}>
          <h4>待遇・福利厚生</h4>
        </div>
        <div className={styles.overviewRight}>
          <p>〇〇〇〇</p>
        </div>
      </div>
      <div className={styles.overviewContainer}>
        <div className={styles.overviewLeft}>
          <h4>研修制度</h4>
        </div>
        <div className={styles.overviewRight}>
          <p>〇〇〇〇</p>
        </div>
      </div>
      <div className={styles.overviewContainer}>
        <div className={styles.overviewLeft}>
          <h4>契約期間</h4>
        </div>
        <div className={styles.overviewRight}>
          <p>〇〇〇〇</p>
        </div>
      </div>
      <div className={styles.overviewContainer}>
        <div className={styles.overviewLeft}>
          <h4>試用期間</h4>
        </div>
        <div className={styles.overviewRight}>
          <p>〇〇〇〇</p>
        </div>
      </div>
      <div className={styles.overviewContainer}>
        <div className={styles.overviewLeft}>
          <h4>社会保険</h4>
        </div>
        <div className={styles.overviewRight}>
          <p>〇〇〇〇</p>
        </div>
      </div>
      <div className={styles.overviewContainer}>
        <div className={styles.overviewLeft}>
          <h4>その他</h4>
        </div>
        <div className={styles.overviewRight}>
          <p>〇〇〇〇</p>
        </div>
      </div>


    </div>

    <div className={styles.entryBtn}>
      <div className={styles.btnTransform}>
        <Link to='/careers/mid/entry'><span>上記内容を確認のうえエントリーする</span><span>View All</span></Link>
      </div>
    </div>


  </DefaultLayout>;